import React from 'react';
import logo from './logo.svg';
import './App.css';
import OAuth2Login from 'react-simple-oauth2-login';

function App() {
  const onSuccess = (response: any) => console.log(response);
  const onFailure = (response: any) => console.error(response);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <OAuth2Login
          clientId="Iv1.008fe88a49c09a0b"
          authorizationUrl="https://github.com/login/oauth/authorize"
          onSuccess={onSuccess}
          onFailure={onFailure}
          responseType="token"
          redirectUri="http://localhost:3000/oauth-callback"
        />
      </header>
    </div>
  );
}

export default App;
